.hero{
    width: 98.9vw;
    height: 70vh;
    position: relative;
}

.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1{
    padding-top: 4rem !important;
}

img{
    width: 100%;
    height: 90%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: #cecccc;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p{
    font-size: 1.7rem;
    color: #191717;
    padding-top: 10px;
    padding-left: 690px;
}

.hero-text .show{
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
}

.hero-text .hide{
    display: none;
}

@media screen and (max-width: 55px){
    .hero-text h1{
        padding: 10px 20px;
    }

    .hero-text p{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show{
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}