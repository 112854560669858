* {
    margin: 0;
    padding: 0;
    font-family: 'Times New Roman', Times, serif;
    box-sizing: border-box;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-top: 90px;
}

.form {
    padding-top: 55px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    height: 40%;
    margin-top: -20px;
}

.personal-info h2 {
    padding: 0px;
}

.billing-details h2 {
    padding: 0px;
}

.form .container form {
    display: flex;
    flex-direction: column;
}

.form .container form input,
.form .container form textarea,
.form .container form select {
    margin: 8px 0;
    padding: 10px;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.form .container form input:focus,
.form .container form textarea:focus,
.form .container form select:focus {
    border-color: #0cc0df;
}

.form .container form button {
    width: 100%;
    padding: 10px 0;
    margin: 10px 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: #0cc0df;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.form .container form button:hover {
    background: #09a8c6;
}

.form .container form .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 170px;
}

.form .container form .button-container button {
    width: 48%;
}

.button-container button {
    padding: 20px;
    align-items: center;
}

.form h2 {
    margin: 10px 0;
    color: #333;
}

.ngos h2 {
    padding: 0px;
}

/* Popup/Modal Styles */
.modal {
    background: white;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    max-width: 400px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    margin: 0 auto; /* Centers modal horizontally */
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for focus */
    display: flex;
    justify-content: center;
    align-items: center; /* Centers modal */
}

.modal .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centers content */
    gap: 15px; /* Adds space between elements */
}

.modal .content h3{
    margin-right: 307px;
}

.modal img {
    max-width: 100%; /* Ensures image doesn't overflow */
    height: auto; /* Maintains aspect ratio */
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Shadow for the image */
}

.modal button {
    background: #0cc0df;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px; /* Adds space between buttons */
}

.modal button:hover {
    background: #09a8c6; /* Hover effect for button */
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .form {
        padding: 10px;
    }

    .modal {
        max-width: 90%; /* Adapts to smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .form .container form input,
    .form .container form select {
        width: calc(100% - 20px);
    }

    .form .container form .button-container button {
        width: 100%; /* Adjusts buttons for smaller screens */
    }
}
