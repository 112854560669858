*.glass {
  position: relative;
  margin-top: 75px;
  background: rgba(225, 225, 225, 0.05);
  border-radius: 6px;
  overflow: hidden;
  z-index: 10;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-top: 1px solid rgba(225, 225, 225, 0.2);
  border-left: 1px solid rgba(225, 225, 225, 0.2);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
  min-width: 98.9vw;
  padding: 40px 30px;
}

.glass__form {
  position: relative;
  display: grid;
}

.glass__form h4 {
  font-family: sans-serif;
  text-align: center;
  margin: 10px;
}

.glass__form__group {
  margin: 10px 0;
}

.glass__form__input {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
}

.glass__form__input:focus {
  background: rgba(255, 255, 255, 0.5);
}

.glass__form__btn {
  background: #0cc0df;
  border: 1px solid #0cc0df;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.glass__form__btn:hover {
  background: transparent;
  color: #0cc0df;
  transition: all ease-in-out 0.3s;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #000;
}