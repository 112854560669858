*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


.App {
  font-family: sans-serif;
  text-align: center;
}