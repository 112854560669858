/* Cause.css */

.container {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px 40px ;
}
  
h2 {
    margin-bottom: 10px;
    padding: 0px 40px;
    text-align: left;
    font-size: 30px;
    font-weight: 800;
}
  
p {
    text-align: left;
    padding: 30px 40px 23px;
    font-size: 17px;
    font-weight: 400;
}  

@media screen and(max-width: 850px){
    .CausePage{
        margin: 4rem 2rem;
    }

    .cause-page{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }
}