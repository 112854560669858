.HomePage {
    margin: 4rem 6rem;
}

.HomePage h1{
    font-size: 4rem;
}

.HomePage button{
    background-color: #222b35;

    color: #fff;
    padding: 20px 30px;
    border: none;
    cursor: pointer;
}

.first-page{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.first-page-reverse{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.fp-text{
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}

.fp-text h2{
    padding-bottom: 1rem;
}

.image{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.image img{
    width: 49%;
    height: 350px;
    object-fit: fill;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
}

.image img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and(max-width: 850px){
    .HomePage{
        margin: 4rem 2rem;
    }

    .first-page{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .first-page-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4.5rem;
    }

    .first-text{
        width: 100%; 
        font-size: 2rem; 
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
    }

    .image img{
        height: 20px;
    }
}