/* Default styles */
.achievements-container {
    width: 100vw; /* Set width to cover the full viewport */
    max-width: 1000px; /* Limit maximum width */
    margin: 0 auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: 20px;
    text-align: center;
}

.hn {
    font-size: 25px;
    grid-column: 1 / -1; /* Span the full width of the grid */
}

.achievement-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.achievement-item img {
    width: 100%; /* Ensure the image fills the container */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Limit image width to the container width */
    max-height: 600px; /* Set a maximum height for the images */
    border-radius: 8px;
    border: 2px solid #ccc; /* Add a border with 2px width and a light gray color */
    box-sizing: border-box; /* Ensure the border width is included in the image size */
}

.achievement-item p {
    margin-top: 10px;
    text-align: center;
    font-style: italic;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .achievements-container {
        grid-template-columns: repeat(1, 1fr); /* Change to a single column layout */
        max-width: 90%; /* Adjust maximum width */
    }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
    .achievement-item img {
        max-height: 400px; /* Adjust maximum height for smaller screens */
    }
}
