.NavbarItems{
    background-color: rgb(184, 195, 193);
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 95%;
    height: 80px;
    border-radius: 15px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
}

.navbar-logo {
    color: #222;
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center; /* Align the logo and text vertically */
}

.logo-img {
    width: 50px; /* Adjust the width of the logo */
    margin-right: 10px; /* Add some margin to separate the logo from text */
    border-radius: 30px;
}


.nav-menu{
    display: grid;
    grid-template-columns: repeat(12, auto);
    grid-gap: 20px;
    list-style: none;
    align-items: center; 
}

.nav-links{
    text-decoration : none;
    color: #222;
    font-size: 1.32rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    position: relative;
}

.nav-links i{
    padding-right: 10px;
}

.nav-links:hover{
    background-color: #01959a;
    color:#fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile{
    display: none;
}



button{
    padding: 0.5rem 1rem;
    white-space: nowrap; 
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

button:hover{
    background-color: #151526;
    color: #fff;
}

.menu-icons{
    display: none;
}

/* Dropdown Menu */
.sub-menu {
    display: none;
    position: absolute;
    top: 100%; /*earlier it was calc(100%+5)*/
    left: 8;
    right: auto;
    background-color: rgb(74, 191, 172);
    list-style: none;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-links:hover .sub-menu {
    display: block;
}

.sub-menu a{
    color: #151526;
}

.sub-menu li {
    margin-top: 5px;
    text-align: left;
    padding: 10px 5px;
}

.sub-menu.active,
.sub-menu:hover {
    display: block;
}


@media screen and (max-width: 1110px){
    .NavbarItems{
        z-index: 99;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }

    .nav-menu.active{
        left: 0;
        opacity:1;
        z-index:-1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .nav-links:hover{
        background: #01959a;
        transition: none;
    }

    button{
    display: none;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        margin: auto;
        padding: 1.5rem;
        border-radius: 4px;
        width: 80%;
        background: #01959a;
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .menu-icons{
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    }

    
}