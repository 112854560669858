.visualize-container{
    margin: 4rem 6rem;
    text-align: center;
    min-width: 87.7vw;
}

.visualize-container h1{
    padding-bottom:1rem;

}

.visualize-container p{
    padding-bottom: 2rem;
}

@media screen and (max-width: 850px){
    .about-container{
        margin: 4rem 2rem;
    }
}